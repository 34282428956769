import pickBy from 'lodash/pickBy';

import {IOption} from '@/shared/models/tasksFilterModel.ts';
import {ICompany} from '@/shared/models/companyModel.ts';
import {IFile} from '@/shared/models/fileModel.ts';
import {IDepartment} from '@/shared/models/dictionaryModel.ts';
import {IUser} from '@/shared/models';

export type FormValues = {
  chatId: number;
  chatMessageIds: number[];
  company: ICompany;
  taskType?: IOption;
  employee: IUser;
  user?: IUser;
  department: IDepartment;
  title: string;
  content?: string;
  attachments?: IFile[];
  delayAt?: string | null | undefined;
  delayReason?: string;
};

export const prepareRequestData = (data: FormValues) => {
  const {
    chatId,
    chatMessageIds,
    company,
    department,
    taskType,
    employee,
    user,
    title,
    content,
    attachments,
    delayReason,
    delayAt = null
  } = data;

  if (!taskType?.id) {
    throw new Error('Type id не задан');
  }

  let taskStatusId = 1;

  if (delayAt) {
    taskStatusId = 6;
  }

  // 1. При создании задачи и подзадачи необходимо слать unit_id выбранного отдела
  // 2. Если выбран кросс юнитовый отдел при создании задачи, нужно слать в запросе unit_id компании
  const unitId = department?.isCrossUnit ? company.unitId : department.unitDepartment?.unitId;

  const preparedData = {
    title,
    chat_id: chatId,
    chat_message_ids: chatMessageIds,
    company_id: company.id,
    employee_id: employee.id,
    user_id: user?.id,
    task_type_id: taskType.id,
    task_status_id: taskStatusId,
    department_id: department.id,
    unit_id: unitId,
    delay_at: delayAt,
    inner_task_comment: {
      content,
      attachments
    },
    delay_task_comment: {
      content: delayReason
    }
  };

  return pickBy(preparedData);
};
