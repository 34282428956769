import React, {FC, memo, useCallback, useEffect, useRef, useState} from 'react';
import {
  Box,
  Checkbox,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  styled,
  Tooltip,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {CheckCircle, KeyboardArrowDown, Reply} from '@mui/icons-material';
import {useSelector} from 'react-redux';

import {IMessage} from '@/shared/models/messageModel';
import {IFile} from '@/shared/models/fileModel';
import {checkSize} from '@/shared/utils/fileUtils';
import {ETaskStatuses} from '@/shared/constants/taskStatuses';
import prepareAddFiles from '@/shared/utils/addFiles';
import {chatErrorSelector} from '@/stores/TaskChatStore/TaskChatSelector';
import {setChatErrorMessage} from '@/stores/TaskChatStore/TaskChatStore';
import {useAppDispatch} from '@/stores/hooks';
import {ErrorTaskCondition} from '@/modals/confirmation/ErrorTaskCondition';
import {IChatMessage} from '@/shared/models/chatModel';
import {ChatReplyCloseButton} from '@/shared/styles/chatStyles.ts';
import {messageUserFullName} from '@/shared/utils/chatUtils.ts';
import {HtmlContent} from '@/components/HtmlContent';
import {ChatTextField} from '@/components/Chat/components/ChatTextField/index';
import {ChatFileList} from '@/components/Chat/components/ChatFileList/index';
import {ChatMessage} from '@/components/Chat/components/ChatMessage/index';

import s from './Chat.module.css';

const StyledIconButton = styled(IconButton)(() => ({
  '&:hover': {
    color: 'white',
    transition: 'color 0.2s ease-in-out'
  },
  color: 'var(--nav-color-inactive)'
}));

interface IProps {
  messages: IMessage[] | IChatMessage[];
  status?: number;
  isDisabled?: boolean;
  isLoadingMessages?: boolean;
  isLoadingSubmit?: boolean;
  hasAllButton?: boolean;
  isDisabledSend?: boolean;
  isDisabledSendAll?: boolean;
  isDisabledInput?: boolean;
  isDisabledInputFile?: boolean;
  isDisplaySystemComments: boolean;
  isSendAll?: boolean;
  onMessageSend: (
    message: string,
    files: IFile[],
    toAll?: boolean,
    replyChatMessageId?: number
  ) => void;
  onGetFile: (file: IFile) => void;
  confirmContent?: () => React.ReactNode;
  isTaskChat?: boolean;
  isMessageChat?: boolean;
  source?: number;
  chatId?: string;
  isLoadingPage?: boolean;
  customHandleScroll?: (chatRef: React.MutableRefObject<HTMLUListElement | null>) => void;
  isSelectingChatMessages?: boolean;
  setSelectedChatMessages?: (messages: (prevSelected: any[]) => any[]) => void;
  hasMore?: boolean;
  isFetchingMessages?: boolean;
  onLoadMoreMessagesForScroll?: () => void;
}

const Chat: FC<IProps> = ({
  messages = [],
  status,
  isDisabled,
  isLoadingMessages,
  isLoadingSubmit,
  hasAllButton,
  isDisabledSend: initialIsDisabledSend = false,
  isDisabledSendAll: initialIsDisabledSendAll = false,
  isDisabledInput,
  isDisabledInputFile,
  isDisplaySystemComments,
  isSendAll = false,
  onMessageSend,
  onGetFile,
  confirmContent,
  isTaskChat = false,
  isMessageChat = false,
  source,
  chatId,
  isLoadingPage = false,
  customHandleScroll,
  isSelectingChatMessages,
  setSelectedChatMessages,
  hasMore,
  isFetchingMessages,
  onLoadMoreMessagesForScroll
}) => {
  const [message, setMessage] = useState('');
  const [replyingToMessage, setReplyingToMessage] = useState<IChatMessage | null>(null);
  const [contextMenuMessage, setContextMenuMessage] = useState<IChatMessage | null>(null);
  const [contextMenu, setContextMenu] = useState<{mouseX: number; mouseY: number} | null>(null);
  const chatRef = useRef<HTMLUListElement | null>(null);
  const [files, setFiles] = useState<IFile[]>([]);
  const [errorFileSize, setErrorFileSize] = useState<string | null>(null);
  const [openCondition, setOpenCondition] = useState<boolean>(false);
  const [isDisabledSend, setIsDisabledSend] = useState<boolean>(initialIsDisabledSend);
  const [isDisabledSendAll, setIsDisabledSendAll] = useState<boolean>(initialIsDisabledSendAll);
  const [previousMessagesLength, setPreviousMessagesLength] = useState(0);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isCheckedChatMessageIds, setIsCheckedChatMessageIds] = useState<{[key: string]: boolean}>(
    {}
  );
  const messageRefs = useRef<{[key: string]: HTMLLIElement | null}>({});
  const [messageIdToScrollTo, setMessageIdToScrollTo] = useState<number | null>(null);
  const [highlightedMessageId, setHighlightedMessageId] = useState<number | null>(null);

  const dispatch = useAppDispatch();

  const chatErrorMessages = useSelector(chatErrorSelector);

  useEffect(() => {
    if (status) {
      dispatch(setChatErrorMessage(null));
    }
  }, [status, dispatch]);

  const minLengthCommentError = 'Минимальная длина сообщения 10 символов';

  const handleCheckMinLengthMessage = useCallback(
    (message: string) => {
      if (!isTaskChat) {
        return;
      }

      setMessage(message);
    },
    [isTaskChat]
  );

  const takeInWorkError = 'Возьмите задачу в работу';

  const handleCheckStatus = useCallback(
    (message: string) => {
      if (!isTaskChat) {
        return;
      }

      const isStatusNew = status === ETaskStatuses.New;

      if (isStatusNew) {
        setOpenCondition(true);
        dispatch(setChatErrorMessage([takeInWorkError]));
      } else {
        setMessage(message);
      }
    },
    [dispatch, isTaskChat, status]
  );

  const handleContextMenu = (event: React.MouseEvent, message: IChatMessage) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null ? {mouseX: event.clientX - 2, mouseY: event.clientY - 4} : null
    );
    setContextMenuMessage(message);
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleCancelReply = () => {
    setReplyingToMessage(null);
  };

  useEffect(() => {
    if (!isTaskChat) {
      return;
    }

    const shouldDisableSend = message.replace(/(<([^>]+)>)/gi, '').trim().length < 10;
    const shouldDisableSendAll = !shouldDisableSend ? !isSendAll : shouldDisableSend;

    if (!shouldDisableSend) {
      dispatch(setChatErrorMessage(null));
    }

    if (
      shouldDisableSend &&
      (!chatErrorMessages || chatErrorMessages.indexOf(minLengthCommentError) === -1)
    ) {
      dispatch(setChatErrorMessage([minLengthCommentError]));
    }

    setIsDisabledSend(shouldDisableSend);
    setIsDisabledSendAll(shouldDisableSendAll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, message, isTaskChat, isSendAll]);

  const handleMessageSend = useCallback(
    (message: string, toAll?: boolean) => {
      onMessageSend(message, files, toAll, replyingToMessage?.id);
      setFiles([]);
      setReplyingToMessage(null);
      dispatch(setChatErrorMessage(null));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatId, files, status, replyingToMessage]
  );

  const handleDeleteFile = (idx: number) => {
    if (files) {
      const newFiles = [...files.slice(0, idx), ...files.slice(idx + 1)];

      setFiles(newFiles);
    }
  };

  const handleAttachFiles = useCallback(async (files: FileList) => {
    if (files) {
      const formattedFiles = await prepareAddFiles(files);
      if (formattedFiles) {
        setFiles(state => [...state, ...formattedFiles]);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(setChatErrorMessage(null));
    setErrorFileSize(null);

    if (files) {
      const isSendEmail = source === 1 || source === 3;
      const errorMessage = checkSize(files, isSendEmail);
      setErrorFileSize(errorMessage);
    }
  }, [files, dispatch, source]);

  const lastMessageRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    setPreviousMessagesLength(0);
  }, [chatId]);

  useEffect(() => {
    if (!previousMessagesLength) {
      lastMessageRef?.current?.scrollIntoView({behavior: 'auto'});
    }

    if (
      !isLoadingPage &&
      ((isMessageChat && !showScrollButton) || !isMessageChat) &&
      messages.length - previousMessagesLength == 1
    ) {
      lastMessageRef?.current?.scrollIntoView({behavior: 'smooth'});
    }

    setPreviousMessagesLength(messages.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, isMessageChat, previousMessagesLength, isLoadingPage]);

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTo({
        top: chatRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const handleScroll = useCallback(() => {
    if (chatRef.current) {
      const {scrollTop, scrollHeight, clientHeight} = chatRef.current;

      const isScrolledUp = scrollHeight - scrollTop - clientHeight > scrollHeight * 0.3;
      setShowScrollButton(isScrolledUp);

      if (customHandleScroll) {
        customHandleScroll(chatRef);
      }
    }
  }, [chatRef, customHandleScroll]);

  const handleSelectMessage = (message: IMessage | IChatMessage) => {
    if (setSelectedChatMessages) {
      setSelectedChatMessages(prevSelected => {
        let updatedSelected;

        if (isCheckedChatMessageIds[message.id]) {
          updatedSelected = prevSelected.filter((m: {id: number}) => m.id !== message.id);
        } else {
          updatedSelected = [...prevSelected, message];
        }

        return updatedSelected.sort((a: {id: number}, b: {id: number}) => a.id - b.id);
      });
    }

    setIsCheckedChatMessageIds(prevState => ({
      ...prevState,
      [message.id]: !prevState[message.id]
    }));
  };

  const onScrollToMessage = (messageId: number) => {
    setMessageIdToScrollTo(messageId);
  };

  useEffect(() => {
    if (messageIdToScrollTo !== null) {
      const messageElement = messageRefs.current[messageIdToScrollTo];
      if (messageElement) {
        if (!isLoadingMessages && !isFetchingMessages && !isLoadingPage) {
          messageElement.scrollIntoView({behavior: 'smooth', block: 'center'});

          setHighlightedMessageId(messageIdToScrollTo);
          setTimeout(() => setHighlightedMessageId(null), 3000);

          setMessageIdToScrollTo(null);
        }
      } else if (hasMore && onLoadMoreMessagesForScroll) {
        onLoadMoreMessagesForScroll();
        if (chatRef.current) {
          chatRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
      } else {
        console.info('Сообщение не найдено и нет больше сообщений для загрузки');
        setMessageIdToScrollTo(null);
      }
    }
  }, [
    messages,
    messageIdToScrollTo,
    hasMore,
    isLoadingMessages,
    isFetchingMessages,
    isLoadingPage,
    onLoadMoreMessagesForScroll
  ]);

  useEffect(() => {
    setIsCheckedChatMessageIds(() => {
      return {};
    });
    if (setSelectedChatMessages) {
      setSelectedChatMessages(() => {
        return [];
      });
    }
  }, [isSelectingChatMessages, setSelectedChatMessages]);

  useEffect(() => {
    const element = chatRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <Paper className={s.chat}>
      {openCondition && (
        <ErrorTaskCondition open={true} onClose={() => setOpenCondition(false)} isChat />
      )}
      <div className={s.inner}>
        <ul className={s.messages} ref={chatRef}>
          {messages.length > 0 &&
            messages.map((message, index) => (
              <li
                style={{listStyleType: 'none'}}
                key={message.id}
                ref={el => {
                  messageRefs.current[message.id] = el;
                  if (index === messages.length - 1) {
                    lastMessageRef.current = el;
                  }
                }}
                className={highlightedMessageId === message.id ? s.highlightedMessage : ''}
              >
                <Stack direction="row">
                  {message.direction && isSelectingChatMessages && (
                    <Stack direction="row" alignItems={'center'}>
                      <Tooltip
                        title={
                          !(isCheckedChatMessageIds[message.id] || false)
                            ? 'Выделить'
                            : 'Не выделять'
                        }
                      >
                        <Checkbox
                          color={'secondary'}
                          checkedIcon={<CheckCircle />}
                          checked={isCheckedChatMessageIds[message.id] || false}
                          onChange={() => handleSelectMessage(message)}
                          inputProps={{'aria-label': 'Выделить'}}
                          sx={{padding: 0, marginRight: 8, flexShrink: 0}}
                        />
                      </Tooltip>
                    </Stack>
                  )}
                  <ChatMessage
                    key={message.id}
                    message={message}
                    onGetFile={onGetFile}
                    chatId={chatId}
                    isDisplaySystemComments={isDisplaySystemComments}
                    isTaskChat={isTaskChat}
                    isMessageChat={isMessageChat}
                    onScrollToMessage={onScrollToMessage}
                    onContextMenu={handleContextMenu}
                  />
                </Stack>
              </li>
            ))}
          {isLoadingMessages && (
            <li className={s.chatCheckButton}>
              <Skeleton variant="rounded" height={45} width={380} />
            </li>
          )}
        </ul>
        {showScrollButton && (
          <Tooltip title="Вниз">
            <StyledIconButton className={s.scrollButton} onClick={scrollToBottom}>
              <KeyboardArrowDown />
            </StyledIconButton>
          </Tooltip>
        )}
      </div>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null ? {top: contextMenu.mouseY, left: contextMenu.mouseX} : undefined
        }
      >
        <MenuItem
          onClick={() => {
            setReplyingToMessage(contextMenuMessage);
            handleCloseContextMenu();
          }}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Reply fontSize="medium" sx={{marginRight: 5}} />
          <Typography variant="body1" sx={{marginTop: 2}}>
            Ответить
          </Typography>
        </MenuItem>
      </Menu>
      <Box className={s.chatTextField}>
        {replyingToMessage && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            className={s.replyBox}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{flex: 1}}
              onClick={() => onScrollToMessage && onScrollToMessage(replyingToMessage.id)}
            >
              <Reply fontSize="medium" sx={{flexShrink: 0}} />
              <Stack sx={{flex: 1}}>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  sx={{
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  В ответ {messageUserFullName(replyingToMessage)}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {replyingToMessage.content && <HtmlContent html={replyingToMessage.content} />}
                </Typography>
              </Stack>
            </Stack>
            <Tooltip title="Отменить">
              <ChatReplyCloseButton onClick={handleCancelReply}>
                <CloseIcon />
              </ChatReplyCloseButton>
            </Tooltip>
          </Stack>
        )}
        {files && <ChatFileList files={files} onDeleteFile={handleDeleteFile} />}
        <ChatTextField
          onChangeFileInput={handleAttachFiles}
          onSendClick={handleMessageSend}
          onSendAll={mes => handleMessageSend(mes, true)}
          onChangeInput={handleCheckMinLengthMessage}
          onFocusInput={handleCheckStatus}
          isLoading={isLoadingSubmit}
          isDisabled={isDisabled || !!errorFileSize}
          hasAllButton={hasAllButton}
          isDisabledSend={isDisabledSend}
          isDisabledSendAll={isDisabledSendAll}
          isDisabledInput={isDisabledInput}
          isDisabledInputFile={isDisabledInputFile}
          isSendAll={isSendAll}
          confirmContent={confirmContent}
        />
        <Fade in={!!chatErrorMessages}>
          <Typography variant="body2" color="error" className={s.errorMessage}>
            {chatErrorMessages ? chatErrorMessages.join(', ') : ''}
          </Typography>
        </Fade>
        <Fade in={!!errorFileSize}>
          <Typography variant="body2" color="error" className={s.errorFileSize}>
            {errorFileSize}
          </Typography>
        </Fade>
      </Box>
    </Paper>
  );
};

export default memo(Chat);
