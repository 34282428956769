import React, {FC, useEffect, useState} from 'react';
import {Button, CircularProgress, Collapse, Stack, Tooltip, Typography} from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import EditIcon from '@mui/icons-material/Edit';
import {Link} from 'react-router-dom';

import {formatUserName} from '@/shared/utils/fieldsUtils';
import {IChatCustomer, IChatUser} from '@/shared/models/chatModel.ts';
import {DividerHr, EllipsisTypography} from '@/shared/styles/chatStyles';
import {EditCustomerName} from '@/modals/edit/EditCustomerName';
import {IUser} from '@/shared/models';

interface IProps {
  isLoadingChat?: boolean;
  isFetchingChat?: boolean;
  refetchChat: () => void;
  chatUsers?: IChatUser[];
  chatCustomers?: IChatCustomer[];
}

const ChatInfoParticipants: FC<IProps> = ({
  isLoadingChat,
  isFetchingChat,
  refetchChat,
  chatUsers,
  chatCustomers
}) => {
  const [isOpenParticipants, setIsOpenParticipants] = useState<boolean>(false);
  const [isEditCustomerOpen, setIsEditCustomerOpen] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<IUser | undefined>(undefined);

  const handleEditCustomerOpen = (customer: IUser) => {
    setSelectedCustomer(customer);
    setIsEditCustomerOpen(true);
  };

  useEffect(() => {
    setIsOpenParticipants(!!chatUsers || !!chatCustomers);
  }, [chatUsers, chatCustomers]);

  return (
    <Stack padding={6}>
      <Button
        variant={'contained'}
        color={'secondary'}
        onClick={() => setIsOpenParticipants(prevState => !prevState && !isFetchingChat)}
      >
        <Typography variant="h2">Участники чата</Typography>
      </Button>
      {isLoadingChat && (!chatUsers || !chatCustomers) && (
        <Stack padding={6} alignItems={'center'}>
          <CircularProgress color="inherit" />
        </Stack>
      )}
      <Collapse in={isOpenParticipants} timeout="auto" unmountOnExit>
        {chatUsers && (
          <>
            <Stack direction="column" spacing={2} paddingTop={8}>
              <Typography variant="subtitle2" fontSize={16}>
                Сотрудники
              </Typography>
              {chatUsers.map(chatUser => (
                <React.Fragment key={chatUser.user.id}>
                  <DividerHr />
                  <Stack direction={'column'} paddingX={8}>
                    <Link
                      to={`https://peb.bitrix24.ru/company/personal/user/${chatUser.user.bitrixId}/`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      <EllipsisTypography variant="body1">
                        {formatUserName(chatUser.user)}
                      </EllipsisTypography>
                    </Link>
                    <EllipsisTypography variant="body2" sx={{color: 'var(--mute-color)'}}>
                      {chatUser.user.position}
                    </EllipsisTypography>
                  </Stack>
                </React.Fragment>
              ))}
            </Stack>
          </>
        )}
        {chatCustomers && (
          <>
            <Stack direction="column" spacing={2} paddingTop={8}>
              <Typography variant="subtitle2" fontSize={16}>
                Клиенты
              </Typography>
              {chatCustomers.map(chatCustomer => (
                <React.Fragment key={chatCustomer.customer.id}>
                  <DividerHr />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{width: '100%'}}
                  >
                    <Stack direction="row" alignItems="center">
                      {chatCustomer.customer?.isTelegram && (
                        <Tooltip title={'Telegram'}>
                          <TelegramIcon sx={{color: 'black', width: '20px'}} />
                        </Tooltip>
                      )}
                      <Stack
                        direction="column"
                        paddingX={chatCustomer.customer?.isTelegram ? 4 : 8}
                      >
                        <EllipsisTypography variant="body1">
                          {formatUserName(chatCustomer.customer)}
                        </EllipsisTypography>
                        <EllipsisTypography variant="body2" sx={{color: 'var(--mute-color)'}}>
                          {chatCustomer.customer.email}
                        </EllipsisTypography>
                      </Stack>
                    </Stack>
                    {chatCustomer.customer?.isTelegram && (
                      <Tooltip title={'Редактировать'}>
                        <EditIcon
                          sx={{color: 'black', width: '20px', cursor: 'pointer'}}
                          onClick={() => handleEditCustomerOpen(chatCustomer.customer)}
                        />
                      </Tooltip>
                    )}
                  </Stack>
                </React.Fragment>
              ))}
            </Stack>
          </>
        )}
      </Collapse>

      <EditCustomerName
        open={isEditCustomerOpen}
        customer={selectedCustomer}
        setIsEditCustomerOpen={setIsEditCustomerOpen}
        refetchChat={refetchChat}
      />
    </Stack>
  );
};

export default ChatInfoParticipants;
