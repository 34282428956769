import {IChatMessage} from '@/shared/models/chatModel.ts';
import {IMessage} from '@/shared/models/messageModel.ts';
import {getUserFullName} from '@/shared/utils/fieldsUtils.ts';

export const messageUserFullName = (message?: IMessage | IChatMessage) => {
  if (!message) return '';

  // @ts-ignore
  const {user, employee, customer} = message;

  if (user) {
    return getUserFullName(user);
  }
  if (employee) {
    return getUserFullName(employee);
  }
  if (customer) {
    return getUserFullName(customer);
  }

  return '';
};
