import React, {FC, useCallback, useState} from 'react';
import {generatePath, useParams} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import {Breadcrumbs, Link, Skeleton, Stack, Typography} from '@mui/material';
import Button from '@mui/lab/LoadingButton';

import {useAppSelector} from '@/stores/hooks';
import {TaskSettingsSection} from '@/components/TaskSettingsSection';
import {taskTypeErrorSelector} from '@/stores/TaskSettingsStore';
import {RoutePaths} from '@/shared/constants/route';
import DeadlineChip from '@/components/DeadlineChip/DeadlineChip';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';
import {useUpdateSupportTaskMutation} from '@/stores/api/support-task-page/support-task-page';
import {ISupportTaskUpdateRequest} from '@/shared/models/supportTaskModel';
import {useSupportTaskData} from '@/scenes/SupportTaskPage/useSupportTaskData';
import {SupportTaskHeaderControls} from '@/scenes/SupportTaskPage/components/SupportTaskHeaderControls';
import {ETaskStatuses} from '@/shared/constants/taskStatuses.ts';
import {AddTaskWatcher} from '@/modals/confirmation/AddTaskWatcher';
import StopWatchTask from '@/modals/confirmation/StopWatchTask/StopWatchTask';
import {useSupportTaskStreaming} from '@/shared/hooks/useSupportTaskStreaming.ts';

import s from '../TaskPage/TaskPage.module.css';

import {SupportTaskChatWrapper} from './components/SupportTaskChatWrapper';
import {DownloadAllButton} from './components/DownloadAllButton';
import {DisplaySystemCommentsButton} from './components/DisplaySystemCommentsButton';
import {SupportTaskStatusButton} from './components/SupportTaskStatusButton';

interface IProps {}

const SupportTaskPage: FC<IProps> = () => {
  const {taskId} = useParams();
  const currentUser = useAppSelector(userInfoSelector);
  const {data: supportTask, isLoading, isError, isSuccess} = useSupportTaskData({taskId});

  const [submitUpdateSupportTask, {isLoading: isUpdateLoading}] = useUpdateSupportTaskMutation();

  const errorType = useAppSelector(taskTypeErrorSelector);

  const [isDisplaySystemComments, setIsDisplaySystemComments] = useState<boolean>(false);

  const status = supportTask?.taskStatusId;
  const isAuthor = currentUser.id === supportTask?.authorId;
  const isExecutor = currentUser.id === supportTask?.userId;

  useSupportTaskStreaming({taskId});

  const isUserSpectator = supportTask?.supportTaskSpectators?.some(
    spectator => spectator.userId === currentUser.id
  );

  const currentUserSpectator = supportTask?.supportTaskSpectators?.find(
    spectator => spectator.userId === currentUser.id
  );
  const supportTaskSpectatorId = currentUserSpectator?.id;

  const isHead =
    currentUser?.isHead &&
    currentUser?.subordinates?.some(
      subordinate => subordinate.subordinateId === supportTask?.authorId
    );

  const isHeadUnit =
    currentUser?.isHeadUnit &&
    currentUser?.userUnits?.some(unit => unit.unitId === supportTask?.unitId);

  const isSupport = currentUser?.userDepartments?.some(
    department => department.departmentId === supportTask?.departmentId
  );

  const isSupportHead = (currentUser?.isHead || currentUser?.isHeadUnit) && isSupport;

  const handleUpdateTask = useCallback(
    (editedParams: ISupportTaskUpdateRequest) => {
      if (!taskId) {
        console.warn('support task id не найден');
        return;
      }
      const params = {
        taskId,
        ...editedParams
      };

      submitUpdateSupportTask(params);
    },
    [submitUpdateSupportTask, taskId]
  );

  const handleChangeStatus = useCallback(
    (newStatus: number, leadTime?: string) => {
      const params: ISupportTaskUpdateRequest = {
        task_status_id: newStatus,
        ...(leadTime ? {lead_time: leadTime} : {})
      };

      if (!supportTask?.userId && currentUser?.id) {
        params['user_id'] = currentUser.id;
      }

      handleUpdateTask(params);
    },
    [currentUser?.id, handleUpdateTask, supportTask?.userId]
  );

  const handleTimeSpendSubmit = useCallback(
    (leadTime: string) => {
      handleChangeStatus(ETaskStatuses.Done, leadTime);
    },
    [handleChangeStatus]
  );

  const [isWatchersOpen, setIsWatchersOpen] = useState(false);

  const handleWatchersOpen = () => {
    setIsWatchersOpen(true);
  };
  const handleWatchersClose = () => {
    setIsWatchersOpen(false);
  };

  const [isStopWatchTaskOpen, setStopWatchTaskOpen] = useState(false);

  const handleStopWatchTaskOpen = () => {
    setStopWatchTaskOpen(true);
  };

  const handleStopWatchTaskClose = () => {
    setStopWatchTaskOpen(false);
  };

  const handleDisplaySystemCommentsButton = () => {
    setIsDisplaySystemComments(!isDisplaySystemComments);
  };

  return (
    <div className="scene">
      {supportTask?.task?.id && (
        <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: '1rem'}}>
          <Link underline="hover" color="inherit" component={RouterLink} to={RoutePaths.Main}>
            Главная
          </Link>
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={generatePath(RoutePaths.TaskPage, {taskId: `${supportTask.task.id}`})}
          >
            {supportTask.task.title}
          </Link>
          <Typography color="text.primary">{supportTask?.title}</Typography>
        </Breadcrumbs>
      )}
      <header className={s.header}>
        <Stack direction="row" spacing={20} useFlexGap alignItems="center">
          <Typography variant="h1">
            Задача техподдержки №<span className="link">{supportTask?.ticket}</span>
          </Typography>
        </Stack>
        <Stack direction="row" spacing={5} useFlexGap alignItems="center">
          <DeadlineChip
            deadlineAt={supportTask?.deadlineAt}
            createdAt={supportTask?.createdAt}
            doneAt={supportTask?.doneAt}
          />
          <SupportTaskStatusButton taskStatus={status} />
        </Stack>

        {taskId && status && (
          <SupportTaskHeaderControls
            taskId={taskId}
            status={status}
            isErrorTask={false}
            isDisabled={isError}
            isUpdateLoading={isUpdateLoading}
            onChangeStatus={handleChangeStatus}
            handleTimeSpendSubmit={handleTimeSpendSubmit}
            error={errorType}
            isAuthor={isAuthor}
            isExecutor={isExecutor}
            isHead={isHead}
            isHeadUnit={isHeadUnit}
            isSupportHead={isSupportHead}
          />
        )}
      </header>
      <section className={s.section}>
        <Stack
          direction="row"
          spacing={20}
          useFlexGap
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3" gutterBottom={false} className={s.title}>
            {!isSuccess && <Skeleton variant="rounded" width={210} height={24} />}
            {isSuccess && supportTask?.title}
          </Typography>
        </Stack>

        {isError && <span className="error">Ошибка. Задача не найдена</span>}
        {!isError && (
          <>
            <TaskSettingsSection
              task={supportTask}
              controls={['noEditCompany', 'pin']}
              onUpdateTask={handleUpdateTask}
            />
            <Stack spacing={8} direction="row" alignItems="flex-end" justifyContent="space-between">
              <TaskSettingsSection
                task={supportTask}
                controls={['department', 'taskType', 'user', 'author']}
                isDisabled={!isSupport}
                onUpdateTask={handleUpdateTask}
              />
              <Stack
                spacing={4}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{flexGrow: 1, whiteSpace: 'nowrap'}}
              >
                {(status === ETaskStatuses.New || status === ETaskStatuses.Progress) &&
                  isUserSpectator && (
                    <Button
                      className={s.button}
                      type="button"
                      variant="contained"
                      color="secondary"
                      disabled={isError}
                      loading={isUpdateLoading}
                      loadingIndicator=""
                      onClick={handleStopWatchTaskOpen}
                    >
                      Перестать наблюдать
                    </Button>
                  )}
                {(isAuthor || isExecutor) &&
                  (status === ETaskStatuses.New || status === ETaskStatuses.Progress) && (
                    <Button
                      className={s.button}
                      type="button"
                      variant="contained"
                      color="secondary"
                      disabled={isError}
                      loading={isUpdateLoading}
                      loadingIndicator=""
                      onClick={handleWatchersOpen}
                    >
                      Наблюдатели
                    </Button>
                  )}
                {isWatchersOpen && supportTask && (
                  <AddTaskWatcher
                    open={isWatchersOpen}
                    onClose={handleWatchersClose}
                    task={supportTask}
                    userId={currentUser.id}
                  />
                )}
                {isStopWatchTaskOpen && (
                  <StopWatchTask
                    open={isStopWatchTaskOpen}
                    onSubmit={handleStopWatchTaskClose}
                    onClose={handleStopWatchTaskClose}
                    supportTaskId={supportTask?.id}
                    supportTaskSpectatorId={supportTaskSpectatorId}
                  />
                )}
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
                  {supportTask?.isHasFiles && taskId && <DownloadAllButton taskId={taskId} />}
                  <DisplaySystemCommentsButton
                    onClick={handleDisplaySystemCommentsButton}
                    isDisplaySystemComments={isDisplaySystemComments}
                  />
                </Stack>
              </Stack>
            </Stack>

            {taskId && (
              <SupportTaskChatWrapper
                taskId={taskId}
                isLoading={isLoading}
                comments={supportTask?.supportTaskComments}
                status={status}
                isDisabled={isAuthor && status === ETaskStatuses.Closed}
                isDisplaySystemComments={isDisplaySystemComments}
              />
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default SupportTaskPage;
