import {IUser} from '@/shared/models';

export const formatUserName = (user?: Partial<IUser>) => {
  if (!user) {
    return '-';
  }

  return [user.secondName, user.firstName, user.middleName].filter(Boolean).join(' ');
};

export const getUserFullName = (user?: Partial<IUser>) => {
  if (!user) {
    return '';
  }
  return `${formatUserName(user)} <${user.email}>`;
};
